h1 button {
    margin-left: 1em;
}

a {
    text-decoration: none;
}

.pagination  {
    float: right!important
}
